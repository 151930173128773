import React from "react";

// Make sure to only include the library in development
if (process.env.REACT_APP_ENV === "staging") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
        // trackAllPureComponents: true,
       // trackHooks: true,
       //  include: [/^.*/]
    });
}